import { Link } from 'gatsby';
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import Logo from "../components/logo"
import headerStyles from "./header.module.scss"

import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Fade from "@material-ui/core/Fade"

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const style = [
    "font-family: Georgia",
    "font-size: 50px",
    "color: #dfdbd5",
    "background-color:#141A3A",
    "line-heigth: 50px",
    "padding: 20px 400px",
    "text-align: center",
  ].join(";")

  const styleTwo = [
    "font-family: Georgia",
    "font-size: 50px",
    "color: #fff",
    "background-color:#141A3A",
    "line-heigth: 50px",
    "padding: 20px 387.5px",
    "text-align: center",
  ].join(";")

  const styleThree = [
    "font-family: Georgia",
    "font-size: 20px",
    "color: #141A3A",
    "line-heigth: 50px",
    "padding: 20px 387.5px",
    "text-align: center",
  ].join(";")
  useEffect(() => {
    async function recruit() {
      console.log(
        "%cCoded with ♥️ %cin Mexico City %cInterested in working with us? dev@ppdc.mx",
        style,
        styleTwo,
        styleThree
      )
      const loc = localStorage
      const lastTime = loc.getItem("lastTime")
        ? new Date(loc.getItem("lastTime"))
        : new Date()
      const passedTime = (new Date() - lastTime) / (1000 * 60 * 60)
      if (
        typeof window !== "undefined" &&
        (passedTime > 3 || passedTime === 0)
      ) {
        await fetch("https://ipapi.co/json/", { mode: "cors" })
          .then(res => res.json())
          .then(data => loc.setItem("ipData", JSON.stringify(data)))
        const w = window
        loc.setItem(
          "window",
          JSON.stringify({ h: w.innerHeight, w: w.innerWidth })
        )
        loc.setItem("initialUrl", w.location.href)
        loc.setItem("lastTime", new Date())
      }
    }
    recruit()
  }, [style, styleTwo, styleThree])

  return (
    <header className={headerStyles.header}>
      <div className={headerStyles.logoTitleContainer}>
        <Link
          className={headerStyles.logo}
          paintDrip
          hex="#141A3A"
          to="/"
          title="home"
        >
          <Logo />
        </Link>
        <h1 className={headerStyles.title}>P&P Digital Consulting</h1>
      </div>
      <nav className={headerStyles.nav}>
        <ul className={headerStyles.navList}>
          <li className={headerStyles.listItem}>
            <Button
              className={headerStyles.linkButton}
              aria-controls="fade-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              Servicios
            </Button>
            <Menu
              id="fade-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              className={headerStyles.menuContainer}
            >
              <MenuItem className={headerStyles.menuItem} onClick={handleClose}>
                <Link
                  className={headerStyles.menuLink}
                  partiallyActive={true}
                  activeClassName={headerStyles.activeMenuLink}
                  swipe
                  exit="exit"
                  entryOffset={100}
                  direction="left"
                  to="/services/marketing"
                  title="marketing"
                >
                  Marketing
                </Link>
              </MenuItem>
              <MenuItem className={headerStyles.menuItem} onClick={handleClose}>
                <Link
                  className={headerStyles.menuLink}
                  partiallyActive={true}
                  activeClassName={headerStyles.activeMenuLink}
                  swipe
                  exit="exit"
                  entryOffset={100}
                  direction="left"
                  to="/services/dev"
                  title="dev"
                >
                  Desarrollo
                </Link>
              </MenuItem>
              <MenuItem className={headerStyles.menuItem} onClick={handleClose}>
                <Link
                  className={headerStyles.menuLink}
                  partiallyActive={true}
                  activeClassName={headerStyles.activeMenuLink}
                  swipe
                  exit="exit"
                  entryOffset={100}
                  direction="left"
                  to="/services/design"
                  title="design"
                >
                  Diseño
                </Link>
              </MenuItem>
              <MenuItem
                className={[
                  headerStyles.menuItem,
                  headerStyles.customerMenuLink,
                ].join(" ")}
                onClick={handleClose}
              >
                <a
                  className={headerStyles.menuLink}
                  partiallyActive={true}
                  activeClassName={headerStyles.activeMenuLink}
                  href="https://ppdc.thinkific.com/"
                  title="Cursos"
                  target="__blank"
                  rel="noopener noreferer"
                >
                  Cursos
                </a>
              </MenuItem>
            </Menu>
          </li>

          <li className={headerStyles.listItem}>
            <Link
              className={headerStyles.link}
              partiallyActive={true}
              activeClassName={headerStyles.activeLink}
              fade
              to="/blog"
              title="blog"
            >
              Blog
            </Link>
          </li>
          <li className={headerStyles.listItem}>
            <Link
              className={headerStyles.link}
              partiallyActive={true}
              activeClassName={headerStyles.activeLink}
              swipe
              exit="exit"
              entryOffset={100}
              direction="up"
              to="/contact"
              title="contact"
            >
              Contacto
            </Link>
          </li>
          <li className={headerStyles.listItem}>
            <Link
              className={headerStyles.link}
              partiallyActive={true}
              activeClassName={headerStyles.activeLink}
              to="/rent"
              title="Renta de equipo de producción profesional"
            >
              Renta de equipo
            </Link>
          </li>
        </ul>
        <div className={headerStyles.buttonContainer}>
          <a
            href="https://ppdc.thinkific.com/"
            title="Cursos"
            target="__blank"
            rel="noopener noreferer"
            className={headerStyles.customerLink}
          >
            <button className={headerStyles.customerButton}>Cursos</button>
          </a>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
