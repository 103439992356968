// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-chamba-js": () => import("./../../../src/pages/chamba.js" /* webpackChunkName: "component---src-pages-chamba-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rent-js": () => import("./../../../src/pages/rent.js" /* webpackChunkName: "component---src-pages-rent-js" */),
  "component---src-pages-services-design-js": () => import("./../../../src/pages/services/design.js" /* webpackChunkName: "component---src-pages-services-design-js" */),
  "component---src-pages-services-dev-js": () => import("./../../../src/pages/services/dev.js" /* webpackChunkName: "component---src-pages-services-dev-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-marketing-js": () => import("./../../../src/pages/services/marketing.js" /* webpackChunkName: "component---src-pages-services-marketing-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-chamba-js": () => import("./../../../src/templates/chamba.js" /* webpackChunkName: "component---src-templates-chamba-js" */),
  "component---src-templates-rent-template-js": () => import("./../../../src/templates/rentTemplate.js" /* webpackChunkName: "component---src-templates-rent-template-js" */)
}

