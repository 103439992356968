import { Link } from 'gatsby';

import React, { useState, useEffect, useRef } from "react"
import Logo from "../components/logo"
import footerStyles from "./footer.module.scss"
import ppdcGold from "../images/ppdc-gold.png"

export default function Footer() {
  const [color, setColor] = useState("")
  const [fillColor, setFillColor] = useState("")
  const [url, setUrl] = useState("")
  const latestUrl = useRef(url)

  function lookUrl() {
    setUrl(window.location.pathname)
    setBgColor()
  }

  function setBgColor() {
    if (url === "/services" || url === "/contact") {
      setColor("#DFDBD5")
      setFillColor("#141A3A")
    } else {
      setColor("#141A3A")
      setFillColor("#DFDBD5")
    }
  }

  useEffect(() => {
    // Used to look at the latest url and the set the color correctly
    latestUrl.current = url
    lookUrl()
  })

  const bgStyles = {
    backgroundColor: color,
  }
  const txStyles = {
    color: fillColor,
  }

  return (
    <footer className={footerStyles.footerArea} style={bgStyles}>
      <img
        alt="logo"
        src={ppdcGold}
        className={footerStyles.logo}
        style={color === "#141A3A" ? {} : { display: "none" }}
      />
      <div
        className={footerStyles.logoBlue}
        style={color === "#141A3A" ? { display: "none" } : {}}
      >
        <Logo />
      </div>
      <div className={footerStyles.textContainer}>
        <p className={footerStyles.direction} style={txStyles}>
          Calle Puebla <br /> Colonia Roma Norte. CDMX.
        </p>
        <p className={footerStyles.contactContainer}>
          <a
            className={footerStyles.contact}
            style={txStyles}
            title="mail"
            href="mailto:contacto@ppdc.mx"
          >
            contacto@ppdc.mx
          </a>
          <a
            className={footerStyles.contact}
            style={txStyles}
            title="phone"
            href="tel:+525586625560"
          >
            +52 (55) 8662 5560
          </a>
        </p>
      </div>
      <nav className={footerStyles.navlist}>
        <ul className={footerStyles.navlistItem}>
          <li className={footerStyles.listItem}>
            <Link
              fade
              to="/"
              className={footerStyles.link}
              title="home"
              style={txStyles}
            >
              Home
            </Link>
          </li>
          <li className={footerStyles.listItem}>
            <Link
              fade
              to="/privacy-policy"
              title="privacy-policy"
              className={footerStyles.link}
              style={txStyles}
            >
              Aviso de Privacidad
            </Link>
          </li>
          <li className={footerStyles.listItem}>
            <Link
              to="/chamba"
              fade
              title="jobs"
              className={footerStyles.link}
              style={txStyles}
            >
              Chamba
            </Link>
          </li>
          <li className={footerStyles.listItem}>
            <a
              href="https://www.messenger.com/t/PPDigitalConsulting"
              title="assessment"
              className={footerStyles.link}
              style={txStyles}
            >
              Solicitar Asesoría
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  )
}
